<template>
  <div class="bg-gray-100 h-full py-8">
    <div v-if="ready" class="max-w-screen-2xl mx-auto grid grid-cols-12 gap-4 h-full">
      <aside class="col-span-12 md:col-span-4 md:order-2">
        <div class="input-area bg-white shadow-md rounded-md border-gray-100 p-4">
          <div class="mb-8">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center bg-gray-50 px-2 py-1" id="main-tab" data-tabs-toggle="#main-tab-content" role="tablist" data-tabs-active-classes="border border-gray-300 rounded-lg bg-white shadow">
              <li class="w-1/2" role="presentation">
                <button class="w-full inline-block p-2 border-b-2 rounded-t-lg" id="text-tab" data-tabs-target="#text" type="button" role="tab" aria-controls="text" aria-selected="false">
                  <i class="fa-solid fa-font"></i>
                  Text
                </button>
              </li>
              <li class="w-1/2" role="presentation">
                <button class="w-full inline-block p-2 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" id="options-tab" data-tabs-target="#options" type="button" role="tab" aria-controls="options" aria-selected="false">
                  <i class="fa-solid fa-sliders"></i>
                  Options
                </button>
              </li>
            </ul>
          </div>

          <div id="main-tab-content">
            <div class="hidden flex flex-col space-y-12" id="text" role="tabpanel" aria-labelledby="text-tab">
              <button class="btn btn--sm btn--dark" @click="addText">
                <i class="fa-solid fa-plus"></i>
                <span>Add text</span>
              </button>
              <TextOption v-for="(text, index) in store.texts" :key="text.id" :id="text.id" :title="`Text #${text.id}`" v-model="store.texts[index]" @active="active(text.id)" @delete="store.texts.splice(index, 1)" :is-active="isActive(text.id)"/>
            </div>

            <div class="hidden" id="options" role="tabpanel" aria-labelledby="options-tab">
              <section class="option-section">
                <h3 class="option-section-heading">Wrap options</h3>
                <div class="flex space-x-4">
                  <div class="w-1/2 flex flex-col space-y-1 input-group">
                    <label class="">Background</label>
                    <div>
                      <BackgroundColorSelect v-model="store.options.background" id="dropdownBackground" title="Select background" include-gradient/>
                    </div>
                  </div>
                  <div class="w-1/2 flex flex-col space-y-1 input-group">
                    <label class="">Outside background</label>
                    <div>
                      <BackgroundColorSelect v-model="store.options.outsideBackground" id="dropdownOutsideBackground" title="Select outside background" include-gradient/>
                    </div>
                  </div>
                </div>

                <div class="flex space-x-4 mt-4">
                  <div class="input-group">
                    <label for="paddingX" class="">Padding X</label>
                    <div class="flex space-x-1 items-center">
                      <input v-model="store.options.paddingX" id="paddingX" type="range" min="0" max="96" class="">
                      <input v-model="store.options.paddingX" type="number" min="0" max="96" class="w-24">
                    </div>
                  </div>
                  <div class="input-group">
                    <label for="paddingY" class="">Padding Y</label>
                    <div class="flex space-x-1 items-center">
                      <input v-model="store.options.paddingY" id="paddingY" type="range" min="0" max="96" class="">
                      <input v-model="store.options.paddingY" type="number" min="0" max="96" class="w-24">
                    </div>
                  </div>
                </div>

                <div class="flex space-x-4 mt-4">
                  <div class="input-group w-1/2">
                    <label for="outsidePadding" class="">Outside padding</label>
                    <div class="flex space-x-1 items-center">
                      <input v-model="store.options.outsidePadding" id="outsidePadding" type="range" min="0" max="96" class="">
                      <input v-model="store.options.outsidePadding" type="number" min="0" max="96" class="w-24">
                    </div>
                  </div>
                  <div class="input-group w-1/2">
                    <label for="borderRadius" class="">Border radius</label>
                    <div class="flex space-x-1 items-center">
                      <input v-model="store.options.borderRadius" id="borderRadius" type="range" min="0" max="96" class="">
                      <input v-model="store.options.borderRadius" type="number" min="0" max="96" class="w-24">
                    </div>
                  </div>
                </div>
              </section>

              <section class="option-section mt-12">
                <h3 class="option-section-heading">Shadow options</h3>
                <div>
                  <div class="flex space-x-4">
                    <div class="input-group">
                      <label for="shadowSize" class="">Shadow size</label>
                      <div class="flex space-x-1 items-center">
                        <input v-model="store.options.shadowSize" id="shadowSize" type="range" min="0" max="96" class="">
                        <input v-model="store.options.shadowSize" type="number" min="0" max="96" class="w-24">
                      </div>
                    </div>
                    <div class="input-group">
                      <label for="shadowBlur" class="">Shadow blur</label>
                      <div class="flex space-x-1 items-center">
                        <input v-model="store.options.shadowBlur" id="shadowBlur" type="range" min="0" max="48" class="">
                        <input v-model="store.options.shadowBlur" type="number" min="0" max="48" class="w-24">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="flex space-x-4">
                    <div class="input-group w-1/2">
                      <label for="shadowOpacity" class="">Shadow opacity</label>
                      <div class="flex space-x-1 items-center">
                        <input v-model="store.options.shadowOpacity" id="shadowOpacity" type="range" min="0" max="1" step="0.1" class="">
                        <input v-model="store.options.shadowOpacity" type="number" min="0" max="1" step="0.1" class="w-24">
                      </div>
                    </div>
                    <div class="w-1/2 flex flex-col space-y-1 input-group">
                      <label class="">Shadow color</label>
                      <div>
                        <BackgroundColorSelect v-model="store.options.shadowColor" id="dropdownShadowColor" title="Select shadow color"/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </aside>

      <main class="col-span-12 md:col-span-8 md:order-1">
        <div class="flex justify-end items-center space-x-4 max-w-[960px] mx-auto px-2 lg:px-0">
          <div>
            <button id="dropdownScaleButton" data-dropdown-toggle="dropdownScale" class="dropdown-button py-3" type="button">
              Image size {{ originWidth * store.options.scale }} x {{ originHeight * store.options.scale }}
              <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
              </svg></button>

            <div id="dropdownScale" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600" data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(522.5px, 6119.5px, 0px);">
              <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownScaleButton">
                <li v-for="scale in scaleOptions" :key="scale[0]">
                  <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                    <div class="flex items-center h-5">
                      <input v-model="store.options.scale" :id="`scale${scale[0]}`" name="helper-radio" type="radio" :value="scale[0]" class="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                    </div>
                    <div class="ms-2 text-sm">
                      <label :for="`scale${scale[0]}`" class="font-medium text-gray-900 dark:text-gray-300">
                        <div>
                          {{ originWidth * scale[0] }} x {{ originHeight * scale[0] }} (x{{ scale[0] }})
                          <span v-if="scale[0] > 2" class="badge badge--purple">Pro</span>
                        </div>
                        <p id="helper-radio-text-4" class="text-xs font-normal text-gray-500 dark:text-gray-300">{{ scale[1] }}</p>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="">
            <button class="btn btn--primary" @click="downloadImage">
              <i class="fa-solid fa-download"></i>
              <span>Download</span>
            </button>
          </div>
        </div>

        <div class="flex flex-col justify-start items-center h-full max-w-[960px] mx-auto mt-4">
          <div
               ref="captureArea"
               :style="`
                background-image: ${store.options.outsideBackground};
                padding: ${Number(store.options.shadowSize) + Number(store.options.outsidePadding)}px;
                `"
          >
            <div
              class="max-w-max relative"
              :style="`
              background-image: ${store.options.background};
              padding: ${store.options.paddingY}px ${store.options.paddingX}px;
              border-radius: ${store.options.borderRadius}px;
              box-shadow: ${boxShadow};
            `"
            >
              <TextInput v-for="(text, index) in store.texts" v-model="store.texts[index]" @active="active(text.id)"/>

              <div class="absolute bottom-0 right-0 rounded bg-gray-600 bg-opacity-80 text-white px-4 py-1 text-sm">SnapText.net</div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref, computed, onMounted} from "vue";
import { toPng } from "html-to-image";
import { useStore } from "$/stores";
import { useFlowbite } from "$/composables/useFlowbite";
import saveAs from 'file-saver'
import TextInput from "./TextInput.vue";
import TextOption from "./TextOption.vue";
import BackgroundColorSelect from "./BackgroundColorSelect.vue";

useFlowbite(['tabs', 'dropdowns'])

const store = useStore()
const ready = ref(false)
const captureArea = ref<HTMLDivElement | null>(null);

const activeSection = ref('')

const boxShadow = computed(() => {
  const { shadowSize, shadowBlur, shadowColor, shadowOpacity } = store.options;
  const rgbaColor = `rgba(${hexToRgb(gradientToHex(shadowColor))}, ${shadowOpacity})`;
  return `${shadowSize}px ${shadowSize}px ${shadowBlur}px ${rgbaColor}`;
});

const originWidth = ref(0)
const originHeight = ref(0)

const resizeObserver = new ResizeObserver((entries) => {
  for (const entry of entries) {
    if (entry.target === captureArea.value) {
      const rect = entry.target.getBoundingClientRect();
      originWidth.value = Math.round(rect.width);
      originHeight.value = Math.round(rect.height);
    }
  }
});

const scaleOptions = [
  [1, 'Perfect for basic posts.'],
  [2, 'Great for sharper visuals.'],
  [3, 'Pro quality for banners.'],
  [4, 'Best for large screens.']
]

onMounted(() => {
  ready.value = true
  setTimeout(() => {
    resizeObserver.observe(captureArea.value)
  }, 500)
});


function gradientToHex(gradient) {
  const hexMatch = gradient.match(/#[0-9a-fA-F]{6}/g);
  return hexMatch ? hexMatch[0] : null;
}

function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return `${r}, ${g}, ${b}`;
}

function downloadImage() {
  toPng(captureArea.value, {
    pixelRatio: store.options.scale
  })
    .then(function (dataUrl) {
      saveAs(dataUrl, 'a.png')
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
}

function active(el) {
  activeSection.value = el
}

function isActive(el) {
  return activeSection.value === el
}

function addText() {
  store.texts.push({
    id: Math.floor(Math.random() * 65536).toString(16).padStart(4, '0'),
    text: "Write your text here...",
    bold: false,
    italic: false,
    size: 18,
    font: "nunito-sans",
    color: "linear-gradient(to right, #000080, #4169E1)",
    align: "left",
  })
}
</script>

<style scoped lang="scss">
</style>
