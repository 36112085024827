import { defineStore } from "pinia";

export const useStore = defineStore('snapTextStore', {
    state: () => ({
        options: {
            paddingX: 20,
            paddingY: 40,
            borderRadius: 30,
            background: "linear-gradient(to right, #fff1eb, #ace0f9)",
            outsideBackground: "linear-gradient(45deg, #f6d365, #fda085)",
            outsidePadding: 36,
            shadowSize: 5,
            shadowBlur: 10,
            shadowColor: "linear-gradient(to right, #6c757d, #6c757d)",
            shadowOpacity: 0.2,
            scale: 1,
        },
        texts: [
            {
                id: '1',
                text: "Welcome to SnapText 🎉",
                bold: true,
                italic: false,
                size: 48,
                font: "noto-serif",
                color: "linear-gradient(to right, #8A2BE2, #FF69B4)",
                align: "center",
            },
            {
                id: '2',
                text: "Create stunning text designs in seconds!",
                bold: false,
                italic: false,
                size: 24,
                font: "nunito-sans",
                color: "linear-gradient(to right, #000080, #4169E1)",
                align: "center",
            },
            {
                id: '3',
                text: "Made with ❤️ using SnapText",
                bold: false,
                italic: true,
                size: 20,
                font: "nunito-sans",
                color: "linear-gradient(to right, #495057, #495057)",
                align: "right",
            }
        ]
    }),

    actions: {
    }
});
