<template>
  <section class="option-section" :class="{'option-section--active': isActive}">
    <h3 class="option-section-heading">{{ title }}</h3>
    <button class="option-section-delete" @click="$emit('delete')">
      <i class="fa-solid fa-trash-can"></i>
      <span class="ml-1">Delete</span>
    </button>
    <div>
      <div class="input-group">
        <textarea v-model="modelValue.text" class="" @focus="$emit('active')" placeholder="Write your text here..."></textarea>
      </div>

      <div class="grid grid-cols-12 gap-4 mt-4">
        <div class="col-span-4">
          <div class="input-group flex items-center">
            <label class="flex items-center">
              <input v-model="modelValue.bold" type="checkbox" class="">
              <span>Bold</span>
            </label>
          </div>
          <div class="input-group flex items-center mt-2">
            <label class="flex items-center">
              <input v-model="modelValue.italic" type="checkbox" class="">
              <span>Italic</span>
            </label>
          </div>
        </div>

        <div class="col-span-4 input-group flex items-center">
          <label class="">Size</label>
          <select v-model="modelValue.size" class="w-[80px]">
            <option v-for="size in fontSizeOptions" :key="size" :value="size">{{ size }}px</option>
          </select>
        </div>

        <div class="col-span-4 input-group flex items-center">
          <label class="">Align</label>
          <select v-model="modelValue.align" class="w-[100px]">
            <option v-for="align in ['left', 'center', 'right']" :key="align" :value="align">{{ align }}</option>
          </select>
        </div>

        <div class="col-span-7 input-group flex space-x-2 items-center">
          <label class="">Font</label>

          <button :id="`dropdown_${id}_font_button`" :data-dropdown-toggle="`dropdown_${id}_font`" data-dropdown-trigger="click" class="dropdown-button" type="button">
            <span :class="modelValue.font">{{ fontFamilyOptions.find(e => e[1] === modelValue.font)[0] }}</span>
            <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg>
          </button>

          <div :id="`dropdown_${id}_font`" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" :aria-labelledby="`dropdown_${id}_font_button`">
              <li v-for="font in fontFamilyOptions" :key="font[1]" :class="font[1]" @click="selectFont(font[1])">
                <div class="cursor-pointer block p-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{ font[0] }}</div>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-span-5 input-group flex space-x-2 justify-end items-center">
          <label>Color</label>
          <TextColorSelect v-model="modelValue.color" :id="id" title="Header color"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Dropdown } from "flowbite";
import { useFlowbite } from "$/composables/useFlowbite";
import TextColorSelect from "&/TextColorSelect.vue";
import {onMounted} from "vue";

const props = defineProps<{
  id: string
  title: string
  modelValue: object
  isActive: boolean
}>()

onMounted(() => {
  useFlowbite(['dropdowns'])
})

const fontSizeOptions = [
  10, 12, 14, 16, 18, 20, 22, 24, 26, 28,
  30, 32, 34, 36, 38, 40, 42, 44, 46, 48
];

const fontFamilyOptions = [
  ['Noto Sans', 'noto-sans'],
  ['Noto Serif', 'noto-serif'],
  ['Nunito Sans', 'nunito-sans'],
  ['Geist Mono', 'geist-mono'],
  ['Sour Gummy', 'sour-gummy']
]

function selectFont(font) {
  props.modelValue.font = font;

  const targetEl = document.getElementById(`dropdown_${props.id}_font`);
  const triggerEl = document.getElementById(`dropdown_${props.id}_font_button`);

  new Dropdown(targetEl, triggerEl, {}, {}).hide()
}
</script>
