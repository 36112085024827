<template>
  <button :id="`${id}Button`" :data-dropdown-toggle="id" class="py-2.5 px-5 flex items-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
    <span class="flex space-x-1 items-center">
      <span class="w-12 h-8" :style="`background-image: ${currentColor}`"></span>
    </span>
    <svg class="w-2 h-2 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
    </svg>
  </button>

  <div :id="id" class="w-[320px] z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-xl w-44 dark:bg-gray-700 dark:divide-gray-600">
    <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
      <div>{{ title }}</div>
    </div>
    <ul class="scrollbar-thin grid grid-cols-2 gap-0 p-2 text-sm text-gray-700 dark:text-gray-200 max-h-[400px] overflow-y-scroll" :aria-labelledby="`${id}Button`">
      <li v-if="includeGradient" v-for="background in gradientColors" :key="background[0]" @click="selectBackground(background[1])">
        <div class="cursor-pointer block p-2 text-white border-4 border-white hover:border-4 hover:border-green-500" :style="`background-image: ${background[1]}`">{{ background[0] }}</div>
      </li>
      <li v-for="background in solidColors" :key="background[0]" @click="selectBackground(background[1])">
        <div class="cursor-pointer block px-4 py-2 text-white border-4 border-white hover:border-4 hover:border-green-500" :style="`background-image: ${background[1]}`">{{ background[0] }}</div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {computed, defineEmits} from "vue";
import { useFlowbite } from "$/composables/useFlowbite";

useFlowbite(['dropdowns'])

const props = withDefaults(
  defineProps<{
    modelValue: string
    id: string
    title: string
    includeGradient?: boolean
  }>(),
  {
    includeGradient: false
  }
)

const currentColor = computed(() => {
  let current = gradientColors.find(i => i[1] === props.modelValue) || solidColors.find(i => i[1] === props.modelValue)
  if (current) return current[1]
})

const gradientColors = [
  ['Soft Horizon', 'linear-gradient(to bottom, #ff9a9e, #fad0c4)'],
  ['Gentle Sky', 'linear-gradient(to top, #a1c4fd, #c2e9fb)'],
  ['Blush Morning', 'linear-gradient(to right, #ff9a9e, #fecfef, #ffdde1)'],
  ['Serene Ocean', 'linear-gradient(120deg, #89f7fe, #66a6ff)'],
  ['Mellow Peach', 'linear-gradient(to right, #f6d365, #fda085)'],
  ['Sandy Shore', 'linear-gradient(135deg, #fbc2eb, #a6c1ee)'],
  ['Mint Whisper', 'linear-gradient(to top right, #d4fc79, #96e6a1)'],
  ['Lavender Breeze', 'linear-gradient(to left, #e0c3fc, #8ec5fc)'],
  ['Sunlit Meadow', 'linear-gradient(to bottom right, #5ee7df, #b490ca)'],
  ['Dusky Twilight', 'linear-gradient(45deg, #4e54c8, #8f94fb)'],
  ['Amber Glow', 'linear-gradient(to right, #ffd194, #d1913c)'],
  ['Petal Pink', 'linear-gradient(60deg, #ff9a9e, #fecfef)'],
  ['Frosted Glass', 'linear-gradient(to bottom, #e0eafc, #cfdef3)'],
  ['Vanilla Dream', 'linear-gradient(to right, #fff1eb, #ace0f9)'],
  ['Celestial', 'linear-gradient(to bottom left, #43c6ac, #191654)'],
  ['Aqua Splash', 'linear-gradient(120deg, #3a7bd5, #3a6073)'],
  ['Sunrise Glow', 'linear-gradient(to right, #ff7e5f, #feb47b, #ffe29f)'],
  ['Golden Mist', 'linear-gradient(135deg, #f6d365, #fda085)'],
  ['Coconut Milk', 'linear-gradient(to top, #92fe9d, #00c9ff)'],
  ['Calm Lagoon', 'linear-gradient(to right, #6a11cb, #2575fc)'],
  ['Morning Dew', 'linear-gradient(to top left, #d4fc79, #96e6a1)'],
  ['Arctic Chill', 'linear-gradient(to right, #5ee7df, #b490ca)'],
  ['Velvet Dreams', 'linear-gradient(to bottom, #4e54c8, #8f94fb)'],
  ['Sunny Fields', 'linear-gradient(45deg, #f6d365, #fda085)'],
  ['Pink Horizon', 'linear-gradient(to right, #ff9a9e, #fecfef)'],
  ['Mystic Ocean', 'linear-gradient(to top, #1a2980, #26d0ce)'],
  ['Candy Land', 'linear-gradient(to right, #fcb045, #fd1d1d, #833ab4)'],
  ['Twilight Glow', 'linear-gradient(to left, #654ea3, #eaafc8)'],
  ['Golden Hour', 'linear-gradient(to right, #f6d365, #fda085, #fbc2eb)'],
  ['Polar Lights', 'linear-gradient(120deg, #1c92d2, #f2fcfe)'],
  ['Sunny Delight', 'linear-gradient(to bottom right, #ffecd2, #fcb69f)'],
  ['Berry Smoothie', 'linear-gradient(to right, #d9a7c7, #fffcdc)'],
  ['Minty Bliss', 'linear-gradient(to top right, #a1ffce, #faffd1)'],
  ['Ethereal Sunset', 'linear-gradient(135deg, #fbc2eb, #a6c1ee, #96e6a1)'],
  ['Amber Waves', 'linear-gradient(to right, #ff7e5f, #feb47b)'],
  ['Royal Sapphire', 'linear-gradient(to top, #141e30, #243b55)'],
  ['Dreamy Pastels', 'linear-gradient(to right, #e0c3fc, #8ec5fc)'],
  ['Neon Splash', 'linear-gradient(120deg, #12c2e9, #c471ed, #f64f59)'],
  ['Frozen Lake', 'linear-gradient(to bottom left, #3a7bd5, #3a6073)'],
  ['Mystic Sunset', 'linear-gradient(to right, #fa709a, #fee140)']
];

const solidColors = [
  ['Soft White', 'linear-gradient(to right, #f8f9fa, #f8f9fa)'],
  ['Light Gray', 'linear-gradient(to right, #e9ecef, #e9ecef)'],
  ['Warm Gray', 'linear-gradient(to right, #dee2e6, #dee2e6)'],
  ['Dusty Blue', 'linear-gradient(to right, #adb5bd, #adb5bd)'],
  ['Slate Blue', 'linear-gradient(to right, #6c757d, #6c757d)'],
  ['Steel Gray', 'linear-gradient(to right, #495057, #495057)'],
  ['Cool Black', 'linear-gradient(to right, #343a40, #343a40)'],
  ['Charcoal', 'linear-gradient(to right, #212529, #212529)'],
  ['Blush Pink', 'linear-gradient(to right, #ffc9c9, #ffc9c9)'],
  ['Coral Pink', 'linear-gradient(to right, #ff8787, #ff8787)'],
  ['Soft Red', 'linear-gradient(to right, #ff6b6b, #ff6b6b)'],
  ['Cherry Red', 'linear-gradient(to right, #fa5252, #fa5252)'],
  ['Burnt Orange', 'linear-gradient(to right, #fd7e14, #fd7e14)'],
  ['Golden Yellow', 'linear-gradient(to right, #fcc419, #fcc419)'],
  ['Lime Green', 'linear-gradient(to right, #d8f5a2, #d8f5a2)'],
  ['Mint Green', 'linear-gradient(to right, #b2f2bb, #b2f2bb)'],
  ['Seafoam Green', 'linear-gradient(to right, #8ce99a, #8ce99a)'],
  ['Teal', 'linear-gradient(to right, #63e6be, #63e6be)'],
  ['Sky Blue', 'linear-gradient(to right, #74c0fc, #74c0fc)'],
  ['Cool Blue', 'linear-gradient(to right, #4dabf7, #4dabf7)'],
  ['Deep Blue', 'linear-gradient(to right, #339af0, #339af0)'],
  ['Royal Blue', 'linear-gradient(to right, #228be6, #228be6)'],
  ['Navy Blue', 'linear-gradient(to right, #1971c2, #1971c2)'],
  ['Lavender', 'linear-gradient(to right, #eebefa, #eebefa)'],
  ['Soft Purple', 'linear-gradient(to right, #d0bfff, #d0bfff)'],
  ['Bright Purple', 'linear-gradient(to right, #9775fa, #9775fa)'],
  ['Magenta', 'linear-gradient(to right, #845ef7, #845ef7)'],
  ['Berry Pink', 'linear-gradient(to right, #ae3ec9, #ae3ec9)'],
  ['Rose Pink', 'linear-gradient(to right, #f06595, #f06595)'],
  ['Peach', 'linear-gradient(to right, #ffc078, #ffc078)'],
  ['Sand', 'linear-gradient(to right, #ffd8a8, #ffd8a8)'],
  ['Warm Beige', 'linear-gradient(to right, #ffe8cc, #ffe8cc)'],
  ['Sunshine Yellow', 'linear-gradient(to right, #ffe066, #ffe066)'],
  ['Mellow Yellow', 'linear-gradient(to right, #ffd43b, #ffd43b)'],
  ['Ivory', 'linear-gradient(to right, #fff3bf, #fff3bf)'],
  ['Olive Green', 'linear-gradient(to right, #adb36e, #adb36e)'],
  ['Forest Green', 'linear-gradient(to right, #5c940d, #5c940d)'],
  ['Brown', 'linear-gradient(to right, #6a4c0d, #6a4c0d)'],
  ['Ash Gray', 'linear-gradient(to right, #ced4da, #ced4da)'],
  ['Pale Mint', 'linear-gradient(to right, #dbffe0, #dbffe0)']
];

const emit = defineEmits(['update:modelValue'])
function selectBackground(background) {
  emit('update:modelValue', background)
}
</script>
