<template>
  <footer class="pb-8">
    <div class="px-4 max-w-screen-xl mx-auto flex justify-between flex-wrap space-y-4">
      <a href="/" class="flex items-center space-x-0 rtl:space-x-reverse">
        <img src="@/images/icon.png" class="h-8" alt="SnapText Logo" />
        <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">SnapText.net</span>
      </a>
      <div class="flex space-x-4 text-sm">
        <router-link :to="{ name: 'MainApp'}" class="link">
          Terms of Service
        </router-link>
        <router-link :to="{ name: 'MainApp'}" class="link">
          Privacy Policy
        </router-link>
        <router-link :to="{ name: 'MainApp'}" class="link">
          Contact
        </router-link>
      </div>
    </div>
    <hr class="my-8">
    <div class="text-center text-sm text-gray-500">© 2024 SnapText.net. All Rights Reserved.</div>
  </footer>
</template>
<script setup lang="ts">
</script>
