import { createRouter, createWebHistory } from 'vue-router'
import PageHome from "&/PageHome.vue";
import MainApp from "&/MainApp.vue";

const routes = [
    {
        path: '/',
        name: 'PageHome',
        component: PageHome,
    },
    {
        path: '/create',
        name: 'MainApp',
        component: MainApp,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
