<template>
  <button :id="`${id}Button`" :data-dropdown-toggle="id" class="py-1 px-2 flex items-center text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
    <span class="flex space-x-1 items-center">
      <span class="w-12 h-8" :style="`background-image: ${currentColor}`"></span>
    </span>
    <svg class="w-2 h-2 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
    </svg>
  </button>

  <div :id="id" class="w-[320px] z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-xl w-44 dark:bg-gray-700 dark:divide-gray-600">
    <div class="px-4 py-3 text-sm text-gray-900 dark:text-white">
      <div>{{ title }}</div>
    </div>
    <ul class="scrollbar-thin grid grid-cols-2 gap-0 p-2 text-sm text-gray-700 dark:text-gray-200 max-h-[400px] overflow-y-scroll" :aria-labelledby="`${id}Button`">
      <li v-for="color in colors" :key="color[0]" @click="selectColor(color[1])">
        <div class="cursor-pointer block p-1 border-4 border-white hover:border-4 hover:border-green-500 flex items-center space-x-1">
          <div class="min-w-8 w-8 h-8" :style="`background-image: ${color[1]}`"></div>
          <div>{{ color[0] }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, computed } from "vue";
import { useFlowbite } from "$/composables/useFlowbite";

useFlowbite(['dropdowns'])

const props = defineProps<{
  modelValue: string
  id: string
  title: string
}>()

const currentColor = computed(() => {
  let current = colors.find(i => i[1] === props.modelValue)
  if (current) return current[1]
})

const colors = [
  // Gradient
  ['Blue Glow', 'linear-gradient(to right, #1E3C72, #2A5298)'],
  ['Soft Pink', 'linear-gradient(to right, #FF9A9E, #FECFEF)'],
  ['Orange Glow', 'linear-gradient(to right, #FF7E5F, #FEB47B)'],
  ['Mint Green', 'linear-gradient(to right, #A8E063, #56AB2F)'],
  ['Golden Sun', 'linear-gradient(to right, #FFD700, #FFA500)'],
  ['Lavender Dream', 'linear-gradient(to right, #E6E6FA, #D8BFD8)'],
  ['Berry Mix', 'linear-gradient(to right, #8A2BE2, #FF69B4)'],
  ['Peach Glow', 'linear-gradient(to right, #FFDAB9, #FF4500)'],
  ['Emerald Glow', 'linear-gradient(to right, #50C878, #228B22)'],
  ['Ice Blue', 'linear-gradient(to right, #E0FFFF, #B0E0E6)'],
  ['Ocean Deep', 'linear-gradient(to right, #000080, #4169E1)'],
  ['Sunset', 'linear-gradient(to right, #FF7E5F, #FEB47B, #FFD700)'],
  ['Meadow Green', 'linear-gradient(to right, #98FB98, #32CD32)'],
  ['Cherry Pink', 'linear-gradient(to right, #FF69B4, #FF1493)'],
  ['Fire Red', 'linear-gradient(to right, #8B0000, #DC143C)'],
  ['Reef Coral', 'linear-gradient(to right, #FF7F50, #FFDAB9)'],
  ['Amber Gold', 'linear-gradient(to right, #FFBF00, #FFD700)'],
  ['Sandy Glow', 'linear-gradient(to right, #F4A460, #FFE4C4)'],
  ['Twilight Purple', 'linear-gradient(to right, #9370DB, #8A2BE2)'],
  ['Velvet', 'linear-gradient(to right, #DDA0DD, #BA55D3)'],
  ['Golden Hour', 'linear-gradient(to right, #FFD700, #FF4500)'],
  ['Purple Haze', 'linear-gradient(to right, #800080, #FF00FF)'],
  ['Lavender Soft', 'linear-gradient(to right, #E6E6FA, #FFF0F5)'],
  ['Sunlit Green', 'linear-gradient(to right, #98FB98, #556B2F)'],
  ['Teal Ocean', 'linear-gradient(to right, #008080, #2C7C8A)'],
  ['Deep Blue', 'linear-gradient(to right, #1E3C72, #191970)'],
  ['Arctic Ice', 'linear-gradient(to right, #00FFFF, #4682B4)'],
  ['Morning Sky', 'linear-gradient(to right, #87CEEB, #4169E1)'],
  ['Minty Cool', 'linear-gradient(to right, #A8E063, #98FB98)'],
  ['Peach Glow', 'linear-gradient(to right, #FFDAB9, #FF4500)'],
  ['Blush Pink', 'linear-gradient(to right, #FFB6C1, #FF69B4)'],
  ['Lavender Ice', 'linear-gradient(to right, #E6E6FA, #D8BFD8)'],
  ['Cherry Glow', 'linear-gradient(to right, #FF69B4, #FFD700)'],
  ['Golden Peach', 'linear-gradient(to right, #FFD700, #FF7E5F)'],
  ['Soft Aqua', 'linear-gradient(to right, #E0FFFF, #B0E0E6)'],
  ['Crimson Velvet', 'linear-gradient(to right, #8B0000, #FF1493)'],
  ['Mint Frost', 'linear-gradient(to right, #98FB98, #E0FFFF)'],
  ['Flame Orange', 'linear-gradient(to right, #FF7E5F, #FF4500)'],

  // Solid
  ['Black', 'linear-gradient(to right, #000000, #000000)'],
  ['White', 'linear-gradient(to right, #FFFFFF, #FFFFFF)'],
  ['Gray', 'linear-gradient(to right, #6c757d, #6c757d)'],
  ['Charcoal', 'linear-gradient(to right, #495057, #495057)'],
  ['Ash', 'linear-gradient(to right, #adb5bd, #adb5bd)'],
  ['Steel', 'linear-gradient(to right, #4682B4, #4682B4)'],
  ['Sky', 'linear-gradient(to right, #87CEEB, #87CEEB)'],
  ['Royal', 'linear-gradient(to right, #4169E1, #4169E1)'],
  ['Midnight', 'linear-gradient(to right, #191970, #191970)'],
  ['Navy', 'linear-gradient(to right, #000080, #000080)'],
  ['Forest', 'linear-gradient(to right, #228B22, #228B22)'],
  ['Lime', 'linear-gradient(to right, #32CD32, #32CD32)'],
  ['Emerald', 'linear-gradient(to right, #50C878, #50C878)'],
  ['Olive', 'linear-gradient(to right, #556B2F, #556B2F)'],
  ['Gold', 'linear-gradient(to right, #FFD700, #FFD700)'],
  ['Amber', 'linear-gradient(to right, #FFBF00, #FFBF00)'],
  ['Orange', 'linear-gradient(to right, #CC5500, #CC5500)'],
  ['Coral', 'linear-gradient(to right, #FF7F50, #FF7F50)'],
  ['Crimson', 'linear-gradient(to right, #DC143C, #DC143C)'],
  ['Red', 'linear-gradient(to right, #8B0000, #8B0000)'],
  ['Blush', 'linear-gradient(to right, #FFB6C1, #FFB6C1)'],
  ['Pink', 'linear-gradient(to right, #FF69B4, #FF69B4)'],
  ['Magenta', 'linear-gradient(to right, #FF00FF, #FF00FF)'],
  ['Purple', 'linear-gradient(to right, #9370DB, #9370DB)'],
  ['Violet', 'linear-gradient(to right, #8A2BE2, #8A2BE2)'],
  ['Lavender', 'linear-gradient(to right, #E6E6FA, #E6E6FA)'],
  ['Peach', 'linear-gradient(to right, #FFDAB9, #FFDAB9)'],
  ['Soft Peach', 'linear-gradient(to right, #FFE4C4, #FFE4C4)'],
  ['Sandy', 'linear-gradient(to right, #F4A460, #F4A460)'],
  ['Beige', 'linear-gradient(to right, #F5DEB3, #F5DEB3)'],
  ['Ivory', 'linear-gradient(to right, #FFFFF0, #FFFFF0)'],
  ['Cream', 'linear-gradient(to right, #FFFDD0, #FFFDD0)'],
  ['Mint', 'linear-gradient(to right, #98FB98, #98FB98)'],
  ['Cyan', 'linear-gradient(to right, #E0FFFF, #E0FFFF)'],
  ['Teal', 'linear-gradient(to right, #008080, #008080)'],
  ['Deep Teal', 'linear-gradient(to right, #2C7C8A, #2C7C8A)'],
  ['Slate', 'linear-gradient(to right, #708090, #708090)'],
  ['Shadow', 'linear-gradient(to right, #696969, #696969)'],
  ['Chocolate', 'linear-gradient(to right, #D2691E, #D2691E)'],
  ['Rich Gold', 'linear-gradient(to right, #B8860B, #B8860B)']
];

const emit = defineEmits(['update:modelValue'])

function selectColor(color) {
  emit('update:modelValue', color)
}
</script>
