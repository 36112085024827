<template>
  <section class="bg-gradient-to-br from-violet-50 via-fuchsia-50 to-purple-50 px-4">
    <div class="max-w-screen-xl mx-auto">
      <div class="py-32">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h1 class="text-3xl md:text-5xl md:leading-normal font-bold">
              Design, Share, Inspire with <span class="bg-gradient-to-br from-rose-600 to-purple-600 bg-clip-text text-transparent">SnapText.net</span>
            </h1>
            <p class="text-xl md:text-2xl mt-4 text-gray-600">Effortlessly create stunning visuals with text and share your ideas like never before.</p>
            <a href='/create' class="mt-12 btn btn--lg btn--primary">
              Create now for Free 🎉
            </a>
            <p class="text-gray-500 italic mt-4">No credit card needed. Start designing in seconds.</p>
          </div>

          <div>
            <img src="@/images/pages/home_intro.png" alt="SnapText" class="shadow-2xl"/>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-white px-4">
    <div class="max-w-screen-xl mx-auto mt-40">
      <h2 class="text-3xl md:text-5xl font-bold text-center">How it works</h2>
      <img src="@/images/pages/how_it_works.gif" alt="SnapText" class="mt-16 mx-auto shadow-2xl"/>
      <ul class="text-center text-xl font-bold text-gray-600 flex flex-col space-y-6 mt-20">
        <li>Add Stunning Text</li>
        <li>Customize to Perfection</li>
        <li>Preview and Download</li>
      </ul>
    </div>
  </section>

  <section class="bg-white px-4">
    <div class="max-w-screen-lg mx-auto mt-40">
      <h2 class="text-3xl md:text-5xl font-bold text-center">Pricing</h2>
      <div class="mt-20 border shadow-xl rounded-xl p-8 mx-auto">
        <div class="grid grid-cols-3 gap-12">
          <div class="md:order-2 md:col-span-2 md:pl-12 md:border-l col-span-3">
            <div class="text-2xl font-bold">Unlock Forever</div>
            <div class="mt-8">SnapText is free to use with a watermark. For those who want full creative freedom, remove the watermark for life with a simple one-time fee of just $14.</div>
            <div class="mt-12 text-lg font-medium text-blue-600">Why Upgrade?</div>
            <ul class="list-none text-sm space-y-4 text-gray-800 mt-4">
              <li class="">
                <span class="mr-2">✨</span>
                <span class="font-medium inline-block mr-1">Export in High Quality</span> – Perfect for sharing and printing.
              </li>
              <li class="">
                <span class="mr-2">🚫</span>
                <span class="font-medium inline-block mr-1">No Watermarks</span> – Keep your designs clean and professional.
              </li>
              <li class="">
                <span class="mr-2">🔄</span>
                <span class="font-medium inline-block mr-1">Lifetime Access to Updates</span> – Stay ahead with new features.
              </li>
              <li class="">
                <span class="mr-2">🚀</span>
                <span class="font-medium inline-block mr-1">Exclusive Early Access</span> – Test premium tools before anyone else.
              </li>
              <li class="">
                <span class="mr-2">📂</span>
                <span class="font-medium inline-block mr-1">Save Your Templates</span> – Quickly reuse your favorite designs anytime.
              </li>
              <li class="">
                <span class="mr-2">🕒</span>
                <span class="font-medium inline-block mr-1">Access Your History</span> – View and edit your previously created visuals with ease.
              </li>
            </ul>
          </div>
          <div class="col-span-3 md:col-span-1">
            <div class="text-lg md:text-xl text-center">
              One Payment. Endless Creativity
            </div>
            <div class="text-center mt-8 flex space-x-2 justify-center items-end">
              <div class="text-7xl font-bold">14</div>
              <div class="text-4xl">USD</div>
            </div>
            <router-link :to="{ name: 'MainApp' }" class="mt-8 mx-auto block btn btn--primary">
              Get access
            </router-link>
            <ul class="mt-8 ml-12">
              <li class="list-disc">No recurring fees.</li>
              <li class="list-disc">Immediate upgrade after payment.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="bg-blue-50 my-20 px-4 py-20">
    <div class="max-w-screen-lg mx-auto">
      <h2 class="text-xl md:text-4xl font-bold text-center">Say goodbye to the hassle of designing text-based visuals manually.</h2>
    </div>
  </section>
</template>
<script setup lang="ts">
</script>
